import React, { Fragment } from "react"
import { Link } from "gatsby"
import Bio from "./bio"
import Logo from "./Logo"

const Layout = ({ location, title, subtitle, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  const isRootPath = location.pathname === rootPath
  let header

  if (isRootPath) {
    header = (
      <Fragment>
        <div className="flex flex-col sm:flex-row justify-center sm:justify-start sm:items-start">
          <Logo size={90} className="mb-4 md:mb-0"></Logo>
          <div className="flex flex-col ml-0 sm:ml-4">
            <h1 className="text-4xl md:text-5xl my-0" style={{}}>
              <Link to="/">{title}</Link>
            </h1>
            <p style={{ marginTop: "1rem" }}>{subtitle}</p>
          </div>
        </div>
      </Fragment>
    )
  } else {
    header = (
      <Fragment>
        <Link className="header-link-home" to="/">
          {title}
        </Link>
        <p>{subtitle}</p>
      </Fragment>
    )
  }

  return (
    <div className="global-wrapper" data-is-root-path={isRootPath}>
      <header className="global-header">{header}</header>
      <main>{children}</main>
      <footer>
        <Bio />
        <div>
          © {new Date().getFullYear()}, Built with
          {` `}
          <a href="https://www.gatsbyjs.com">Gatsby</a>
        </div>
      </footer>
    </div>
  )
}

export default Layout
